<template>
    <div id="ErpIntegrationRun">
        <v-container fluid app>
            <v-layout v-resize="onResize">
                <v-data-table
                    :headers="apiResult.headers"
                    :items="apiResult.items"
                    :items-per-page="30"
                    :footer-props="footerProps"
                    :page.sync="pagination.page"
                    @pagination="updatePage"
                    :item-key="getItemKey()"
                    :search="search"
                    :loading="loading"
                    loading-text="Carregando dados..."
                    class="elevation-1 text-no-wrap"
                    :height="noData ? '' : windowSize.y - 64 - 157 - 70"
                    fixed-header
                    style="width: 100%;"
                >
                    <template v-slot:top>
                        <v-toolbar flat class="rounded-lg">
                            <div class="d-flex align-start mr-4">
                                <v-icon x-large color="primary">
                                    {{ tableIcon }}
                                </v-icon>
                            </div>
                            <div class="d-flex flex-column align-start">
                                <span class="text-h6 font-weight-regular">{{ tableName }}</span>
                            </div>

                            <v-spacer></v-spacer>

                            <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                :label="$vuetify.lang.t('$vuetify.searchLabel')"
                                hide-details
                                single-line
                                dense
                                clearable
                                class="mr-4"
                                style="width: 1%;"
                            />

                            <v-tooltip top>
                                <template v-slot:activator="{on}">
                                    <v-btn
                                        color="primary"
                                        v-on="on"
                                        class="mb-2 mr-4 pa-0"
                                        min-width="48px"
                                        @click="refresh()"
                                    >
                                        <v-icon>mdi-refresh</v-icon>
                                    </v-btn>
                                </template>
                                <span>Atualizar</span>
                            </v-tooltip>

                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        color="primary" dark
                                        v-on="on"
                                        class="mb-2 pa-0"
                                        min-width="48px"
                                        @click="closeTable()"
                                    >
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                </template>
                                <span>Fechar</span>
                            </v-tooltip>

                            <v-dialog v-model="dialogCloseTable" max-width="600px">
                                <v-card>
                                    <div class="d-flex justify-center">
                                        <v-card-title class="headline text-center">Você possui edições não salvas. Tem certeza de que deseja fechar a tabela?</v-card-title>
                                    </div>
                                    <v-card-actions class="px-6 pb-6">
                                        <v-spacer></v-spacer>
                                        <v-btn color="primary" text @click="dialogCloseTable = false" class="mr-4">Cancelar</v-btn>
                                        <v-btn color="primary" @click="$router.push('/')">Confirmar</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-toolbar>

                        <v-divider class="mx-4 mb-2"></v-divider>

                        <div>
                            <v-row class="ml-5" no-gutters>
                                <v-col class="d-flex">
                                    <v-menu
                                        ref="menu"
                                        v-model="menu"
                                        :close-on-content-click="false"
                                        :return-value.sync="dates"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                :value="formattedDates()"
                                                label="De/ Até"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                v-on="on"
                                                clearable
                                                @click:clear="clearSelectedFilter()"
                                                class="mr-4"
                                                style="max-width: 400px;"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker color="primary"
                                            v-model="dates"
                                            no-title
                                            scrollable
                                            range
                                        >
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                text
                                                color="primary"
                                                @click="menu = false"
                                            >
                                                Cancelar
                                            </v-btn>
                                            <v-btn
                                                text
                                                color="primary"
                                                @click="$refs.menu.save(dates), setDatepickerValue()"
                                            >
                                                Confirmar
                                            </v-btn>
                                        </v-date-picker>
                                    </v-menu>

                                    <v-autocomplete
                                        v-model="integrationStatus"
                                        label="Status do processamento"
                                        :items="['OK', 'ERR', 'WIP', 'NEW']"
                                        clearable
                                        style="max-width: 400px;"
                                        class="mr-4"
                                    >
                                    </v-autocomplete>

                                    <v-checkbox
                                        label="Exibir apenas último processamento"
                                        v-model="showLastIntegration"
                                    >
                                    </v-checkbox>
                                </v-col>
                            </v-row>
                        </div>
                    </template>

                    <!-- eslint-disable-next-line -->
                    <template v-slot:item="{ item }">
                        <tr>
                            <template v-for="(header, headerIndex) in apiResult.headers">
                                <td v-if="header.value === 'status'"
                                    :key="headerIndex" 
                                    class="d-block d-sm-table-cell"
                                    style="width: 1%;"
                                    :class="setRowStyle(item)"
                                >
                                    <!-- <v-progress-circular v-if="loading"
                                        size="24"
                                        width="3"
                                        indeterminate
                                        color="primary"
                                    ></v-progress-circular> -->
                                </td>

                                <!-- <td v-if="header.value === 'actions'"
                                    :class="{ 'actions-column text-end': header.value === 'actions'}"
                                    :key="headerIndex" 
                                    class="d-block d-sm-table-cell"
                                    style="width: 1%;"
                                >
                                    <v-progress-circular v-if="loading"
                                        size="24"
                                        width="3"
                                        indeterminate
                                        color="primary"
                                    ></v-progress-circular>
                                </td> -->
                                
                                <td v-else :key="headerIndex" class="d-block d-sm-table-cell"
                                    :class="setRowStyle(item)"
                                >                        
                                    <div v-if="header.type === 'DATE' || header.type === 'DATETIME'" class="d-flex justify-start">
                                        <span>{{ formattedDate(item[header.value]) }}</span>
                                    </div>

                                    <div v-else-if="header.align === 'center'" class="d-flex justify-center">
                                        <span>{{ item[header.value] }}</span>
                                    </div>

                                    <div v-else class="d-flex justify-start">
                                        <span>{{ item[header.value] }}</span>
                                    </div>
                                </td>
                            </template>
                        </tr>
                    </template>
                
                    <template v-slot:no-data>
                        <span class="text-h6 primary--text">{{ noDataMessage }}</span>
                    </template>
                </v-data-table>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
    name: 'ErpIntegrationRun',

    computed: {
        ...mapGetters('auth', ['getHash', 'getMenu']),

        endpoint() {
            return [ this.$ipIntegration, 'erp-integration-run' ]
        },

        tableName() {
            const tableName = this.getTableInfo().tableName
            return tableName ? tableName : 'Erro ao listar a tabela'
        },

        tableIcon() {
            const tableIcon = this.getTableInfo().tableIcon
            return tableIcon ? tableIcon : 'mdi-view-list'
        },
    },

    data() {
        return {
            loading: false,

            tableRows: 10000,

            search: '',

            apiResult: {
                items: [],
                headers: [],
                columns: [],
            },

            pagination: {},
            backupPage: 1,
            footerProps: {
                itemsPerPageOptions: [5, 15, 30, 50],
                itemsPerPageAllText: 'Todos'
            },

            dialogCloseTable: false,
            unsavedChanges: false,

            noDataMessage: '',
            noData: true,

            windowSize: { x: 0, y: 0 },

            dates: [],
            menu: false,

            integrationStatus: null,
            showLastIntegration: true,
        }
    },

    created() {
        this.load()
    },

    methods: {
        getTableInfo() {
            let tableInfo = {
                tableName: '',
                tableIcon: '',
            }

            for (let i = 0; i < this.getMenu.length; i++) {
                let submenu = this.getMenu[i].menus.find(m => m.frontend_route_path === this.$route.path)
                if (submenu) {
                    tableInfo.tableIcon = this.getMenu[i].icon
                    tableInfo.tableName = submenu.label
                }
            }

            return tableInfo
        },

        onResize () {
            this.windowSize = { x: window.innerWidth, y: window.innerHeight };
        },

        getItemKey() {
            return 'id_' + this.endpoint[1].replaceAll('-', '_')
        },

        refresh() {
            this.backupPage = this.pagination.page

            this.noData = true
            this.apiResult = {
                items: [],
                headers: [],
                columns: [],
            }

            this.load()
        },

        async load() {
            this.loading = true
            let payload = this.setPayload()

            try {
                const res = await this.$http.post(this.endpoint[0] + this.endpoint[1] + '/list', payload)

                this.apiResult = {
                    columns: res.data.columns,
                    headers: this.setHeaders(res.data.headers, res.data.columns),
                    items: res.data.rows
                    // items: this.setItems(res.data.rows, res.data.columns)
                }

                // Status
                this.apiResult.headers.unshift({ text: '', value: 'status', sortable: false })

                // Actions
                this.apiResult.headers.push({ text: '', value: 'actions', sortable: false })

                if (!this.apiResult.items.length) {
                    this.noDataMessage = 'Não há dados disponíveis'
                } else {
                    this.noData = false
                }

                this.pagination.page = this.backupPage
                return this.loading = false
            } catch (err) {
                this.$fnError(err)
            }
        },

        setPayload() {
            let conditions = []

            if (this.dates.length === 2) {
                let dates = this.compareDates()
                
                conditions.push(
                    {
                        AndOr: "OR",
                        column: 'execution_start_date',
                        operator: "BETWEEN",
                        value: moment(dates[0]).format("YYYY-MM-DD[T]HH:mm:ss[.000Z]"),
                        value2: moment(dates[1]).format("YYYY-MM-DD[T]HH:mm:ss[.000Z]")
                    },
                    {
                        AndOr: "OR",
                        column: 'execution_finish_date',
                        operator: "BETWEEN",
                        value: `${dates[0]}T23:59:59.000Z`,
                        value2: `${dates[1]}T23:59:59.000Z`
                    }
                )
            } else if (this.dates.length === 1) {
                conditions.push(
                    {
                        AndOr: "OR",
                        column: 'execution_start_date',
                        operator: "BETWEEN",
                        value: `${this.dates[0]}T00:00:00.000Z`,
                        value2: `${this.dates[0]}T23:59:59.000Z`
                    },
                    {
                        AndOr: "OR",
                        column: 'execution_finish_date',
                        operator: "BETWEEN",
                        value: `${this.dates[0]}T00:00:00.000Z`,
                        value2: `${this.dates[0]}T23:59:59.000Z`
                    }
                )
            }

            if (this.integrationStatus) {
                conditions.push({
                    AndOr: "AND",
                    column: 'processing_status',
                    operator: "CONTAINS",
                    value: this.integrationStatus
                })
            }

            return {
                showLastIntegration: this.showLastIntegration,
                filter: {
                    tableRows: this.tableRows,
                    conditions,
                }
            }
        },

        // setItems(items, columns) {
        //     let dateColumns = columns.filter(c => c.columnType === 'DATE' || c.columnType === 'DATETIME')
            // items.forEach(i => {
            //     dateColumns.forEach(c => {
            //         if (i[c.columnAlias]) {
            //             i[c.columnAlias] = moment.utc(i[c.columnAlias]).format("YYYY-MM-DD")
            //         }
            //     })
            // })
            
        //     return items
        // },

        setHeaders(headers, columns) {
            let filteredHeaders = headers.filter(h => !h.hideColumn)

            return filteredHeaders.map((h, i) => {
                let width = ''
                let type = ''
                let rules = []
                let hide = false
                let key = ''
                let length = null
                let index = columns.map(column => column.columnAlias).indexOf(h.value)

                if (index === -1) {
                    index = i
                }

                if (h.width !== undefined ) {
                    width = h.width
                } else {
                    width = "1%"
                }

                if (h.type !== undefined) {
                    type = h.type
                } else {
                    if (h.file) {
                        type = 'IMG'
                    } else {
                        if (columns[index]) {
                            type = columns[index].columnType
                        }
                    }
                }

                if (h.rules !== undefined) {
                    rules.push(h.rules)
                } else {
                    let columnIdIndex = index

                    if (h.columnId) {
                        columnIdIndex = columns.map(column => column.columnAlias).indexOf(h.columnId)
                    }

                    if (columns[columnIdIndex]) {
                        if (columns[columnIdIndex].nullColumn === 'NO') {
                            rules.push('required')
                        }
                    }
                }

                // if h hide is true table column will show but will not appear at edit dialog
                if (h.hide !== undefined) {
                    hide = h.hide
                } else {
                    if (columns[index]) {
                        if (columns[index].key === 'MUL' || columns[index].key === 'PRI') {
                            hide = true
                            key = columns[index].key
                        }
                    }
                }
                
                if (h.length !== undefined) {
                    length = h.length
                } else {
                    if (columns[index]) {
                        length = columns[index].columnLength
                    }
                }
                
                return { ...h, width, type, rules, hide, key, length }
            })
        },

        updatePage (pagination) {
            if (pagination.page !== this.pagination.page) {
                pagination.page = this.pagination.page
            }
            this.pagination = pagination
        },

        closeTable() {
            if (!this.unsavedChanges) {
                this.$router.push('/')
            } else {
                this.dialogCloseTable = true
            }
        },

        formattedDate(date) {
            if (date) {
                return moment(date).format("DD/MM/YYYY HH:mm:ss")
            }
        },

        formattedDates() {
            let date1, date2, formattedDates

            if (this.dates.length === 0) {
                formattedDates = null
            } else if (this.dates.length === 1) {
                date1 = moment.utc(this.dates[0]).format("DD/MM/YYYY")
                formattedDates = date1
            } else if (this.dates.length === 2) {
                let dates = this.compareDates()

                date1 = moment.utc(dates[0]).format("DD/MM/YYYY")
                date2 = moment.utc(dates[1]).format("DD/MM/YYYY")

                formattedDates = `${date1} - ${date2}`
            }

            return formattedDates
        },

        compareDates() {
            let comparedDates
            
            if (moment.utc(this.dates[0]).diff(moment.utc(this.dates[1])) < 0) {
                comparedDates = [this.dates[0], this.dates[1]]
            } else {
                comparedDates = [this.dates[1], this.dates[0]]
            }

            return comparedDates
        },

        setDatepickerValue() {
            this.dates = this.compareDates()
        },

        clearSelectedFilter() {
            this.dates = []
        },

        setRowStyle(item) {
            if (!item.processing_status || item.processing_status === 'OK') {
                return ''
            }

            if (item.processing_status === 'ERR') {
                return 'err-row'
            } else if (item.processing_status === 'WIP') {
                return 'wip-row'
            } else if (item.processing_status === 'NEW') {
                return 'new-row'
            }
        }
    },
}
</script>

<style scoped>
.err-row {
    background-color: rgb(247, 211, 211) !important;
}

.err-row:hover {
    background-color: rgb(241, 172, 172) !important;
}

.wip-row {
    background-color: rgb(253, 253, 177) !important;
}

.wip-row:hover {
    background-color: rgb(231, 211, 120) !important;
}

.new-row {
    background-color: rgb(200, 255, 200) !important;
}

.new-row:hover {
    background-color: rgb(143, 219, 143) !important;
}

.v-small-dialog__activator__content {
    width: 100%;
}

.v-data-footer {
    margin-right: 0 !important; 
}
</style>
