var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"ErpIntegrationRun"}},[_c('v-container',{attrs:{"fluid":"","app":""}},[_c('v-layout',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}]},[_c('v-data-table',{staticClass:"elevation-1 text-no-wrap",staticStyle:{"width":"100%"},attrs:{"headers":_vm.apiResult.headers,"items":_vm.apiResult.items,"items-per-page":30,"footer-props":_vm.footerProps,"page":_vm.pagination.page,"item-key":_vm.getItemKey(),"search":_vm.search,"loading":_vm.loading,"loading-text":"Carregando dados...","height":_vm.noData ? '' : _vm.windowSize.y - 64 - 157 - 70,"fixed-header":""},on:{"update:page":function($event){return _vm.$set(_vm.pagination, "page", $event)},"pagination":_vm.updatePage},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"rounded-lg",attrs:{"flat":""}},[_c('div',{staticClass:"d-flex align-start mr-4"},[_c('v-icon',{attrs:{"x-large":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.tableIcon)+" ")])],1),_c('div',{staticClass:"d-flex flex-column align-start"},[_c('span',{staticClass:"text-h6 font-weight-regular"},[_vm._v(_vm._s(_vm.tableName))])]),_c('v-spacer'),_c('v-text-field',{staticClass:"mr-4",staticStyle:{"width":"1%"},attrs:{"append-icon":"mdi-magnify","label":_vm.$vuetify.lang.t('$vuetify.searchLabel'),"hide-details":"","single-line":"","dense":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mb-2 mr-4 pa-0",attrs:{"color":"primary","min-width":"48px"},on:{"click":function($event){return _vm.refresh()}}},on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v("Atualizar")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mb-2 pa-0",attrs:{"color":"primary","dark":"","min-width":"48px"},on:{"click":function($event){return _vm.closeTable()}}},on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}])},[_c('span',[_vm._v("Fechar")])]),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.dialogCloseTable),callback:function ($$v) {_vm.dialogCloseTable=$$v},expression:"dialogCloseTable"}},[_c('v-card',[_c('div',{staticClass:"d-flex justify-center"},[_c('v-card-title',{staticClass:"headline text-center"},[_vm._v("Você possui edições não salvas. Tem certeza de que deseja fechar a tabela?")])],1),_c('v-card-actions',{staticClass:"px-6 pb-6"},[_c('v-spacer'),_c('v-btn',{staticClass:"mr-4",attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialogCloseTable = false}}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$router.push('/')}}},[_vm._v("Confirmar")])],1)],1)],1)],1),_c('v-divider',{staticClass:"mx-4 mb-2"}),_c('div',[_c('v-row',{staticClass:"ml-5",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex"},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.dates,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.dates=$event},"update:return-value":function($event){_vm.dates=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"mr-4",staticStyle:{"max-width":"400px"},attrs:{"value":_vm.formattedDates(),"label":"De/ Até","prepend-icon":"mdi-calendar","readonly":"","clearable":""},on:{"click:clear":function($event){return _vm.clearSelectedFilter()}}},on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"color":"primary","no-title":"","scrollable":"","range":""},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.$refs.menu.save(_vm.dates), _vm.setDatepickerValue()}}},[_vm._v(" Confirmar ")])],1)],1),_c('v-autocomplete',{staticClass:"mr-4",staticStyle:{"max-width":"400px"},attrs:{"label":"Status do processamento","items":['OK', 'ERR', 'WIP', 'NEW'],"clearable":""},model:{value:(_vm.integrationStatus),callback:function ($$v) {_vm.integrationStatus=$$v},expression:"integrationStatus"}}),_c('v-checkbox',{attrs:{"label":"Exibir apenas último processamento"},model:{value:(_vm.showLastIntegration),callback:function ($$v) {_vm.showLastIntegration=$$v},expression:"showLastIntegration"}})],1)],1)],1)]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_vm._l((_vm.apiResult.headers),function(header,headerIndex){return [(header.value === 'status')?_c('td',{key:headerIndex,staticClass:"d-block d-sm-table-cell",class:_vm.setRowStyle(item),staticStyle:{"width":"1%"}}):_c('td',{key:headerIndex,staticClass:"d-block d-sm-table-cell",class:_vm.setRowStyle(item)},[(header.type === 'DATE' || header.type === 'DATETIME')?_c('div',{staticClass:"d-flex justify-start"},[_c('span',[_vm._v(_vm._s(_vm.formattedDate(item[header.value])))])]):(header.align === 'center')?_c('div',{staticClass:"d-flex justify-center"},[_c('span',[_vm._v(_vm._s(item[header.value]))])]):_c('div',{staticClass:"d-flex justify-start"},[_c('span',[_vm._v(_vm._s(item[header.value]))])])])]})],2)]}},{key:"no-data",fn:function(){return [_c('span',{staticClass:"text-h6 primary--text"},[_vm._v(_vm._s(_vm.noDataMessage))])]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }